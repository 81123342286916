.feedback-container {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.feedback-container > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feedback-container > div > textarea {
  margin-top: 10px;
  resize: none;
  min-height: 130px;
  width: 400px;
  background-color: var(--input);
  color: var(--text-color-light);
}

.feedback-container > div > textarea::placeholder {
  color: var(--text-color-light);
}

.feedback-container > div > div {
  display: flex;
  justify-content: flex-end;
}

.feedback-container > div > div > button {
  padding: 6px 20px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--text-color-light);
  border: 1px solid var(--primary-color);
}

.feedback-container > div > div > button:hover {
  cursor: pointer;
  background-color: var(--text-color-light);
  box-shadow: var(--button-box-shadow);
  color: var(--text-color);
  border: 1px solid var(--text-color-light);
}

.feedback-error-msg {
  color: red;
  font-size: 14px;
}

@media only screen and (max-width: 550px) {
  .feedback-container > div > textarea {
    width: 80vw;
  }
}
