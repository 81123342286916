.logo{
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 20px;
}
.vector img{
    margin-top: 6px;
}
.Welcome_icon{
    display: flex;
    color: #C6FF00;
    margin-top: 40px;
    margin-left: 220px;
    align-items: center;  
}
.text{
    display: flex;
    margin-left: 20px;
    font-size:24px;

}
.vl{
    border-bottom: 1px solid #DCF587;
    width: 820px;
    height: 12px;
    margin-left: 215px;
    margin-top: -17px;
  } 
.form input {
    background-color:#13171D !important;
    color:#C0C0CF;
    border: 1px solid  black;
    Padding: 8px 8px;
    width: 320px;
    margin-bottom: -20px;
    border-radius: 8px;
}
.left_form{
    margin-top: 20px;
    margin-left: 230px !important;
}
.left_form p{
    color: #FFFFFF;
    font-size: 12px;
}
.left_form h6{
    margin-left: 235px;
    margin-top: -0.5px;
    color: #FFFFFF;
}
.left_form Button{

    color:#212134;
    background-color: #C6FF00;
    font-size: 10px;
    border-radius: 10px;
    width: 326px;
    height: 50px;
    margin-top: 12px;
}
.left_form Button:hover{
    
    color:#212134;
    background-color: #C6FF00;

}
.connect p{
    color: #C6FF00 !important;
    font-size: 10px;
    margin-left: 120px;
}
    .social_icon{
        margin-left: 110px;
    }
.connect img{
    margin-left: 10px;
    height: 30px !important;
    width: 29px !important;
}
.login h6{
    margin-left: 80px;
    font-size: 12px;
    margin-top: -2px;
    font-weight: 1000px;

}
.login p{
    margin-left: 110px;
    color:#C6FF00;
    margin-top: -25px;
    
}
.card img{
    width: 313px;
    height: 274px;
}
