.confirm-polyx-buysell-dialog-image {
  width: 50px;
  height: 50px;
  /* margin-left: 5%; */
  border-radius: 50%;
}

.confirm-polyx-buysell-dialog-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  color: var(--text-color);
  font-size: 0.9rem;
}

.confirm-polyx-buysell-dialog-container b {
  border: 1px solid var(--text-color);
  padding: 2px 5px;
}

.confirm-polyx-buysell-dialog-quote {
  /* margin-left: 18%; */
  border: 1px solid var(--text-color);
  padding: 2px 5px;
  font-weight: bold;
}

.confirm-polyx-buysell-dialog-down_arrow {
  color: var(--primary-color);
}

#confirm-dialog-buy_sell-table {
  width: 100%;
}

#confirm-dialog-buy_sell-table thead tr th:first-child,
#confirm-dialog-buy_sell-table thead tr th:last-child {
  width: 25%;
}

#confirm-dialog-buy_sell-table thead tr th:nth-child(2) {
  width: 50%;
}

.confirm-swap-dialog-flex-center {
  display: flex;
  justify-content: center;
}

.confirm-swap-dialog-padding {
  padding: 2px 5px;
}

/*

Share Dialog

*/

.share-dialog-source-container {
  display: flex;
  justify-content: flex-start;
}

.share-dialog-source-container:hover {
  cursor: pointer;
}

.share-dialog-source-inner-container {
  display: flex;
  background-color: var(--primary-color);
  padding: 3px 15px;
  border-radius: 20px;
  gap: 7px;
  align-items: center;
}

.share-dialog-source-text {
  color: var(--text-color-light);
}

.share-dialog-source-icon {
  color: var(--text-color-light);
}

.share-dialog-copy-link-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.share-dialog-copy-link-icon {
  color: var(--text-color);
  font-size: 32px !important;
}

.share-dialog-copy-link-icon:hover {
  cursor: pointer;
}

.share-dialog-copy-link {
  color: var(--primary-color);
  margin: 5px 0px;
  font-size: 16px;
}

.share-dialog-invite-via-text {
  color: var(--primary-color);
  margin: 5px 0px;
}

.share-dialog-divider {
  width: 100%;
  height: 1px;
  background-color: var(--primary-color);
}
