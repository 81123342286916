.login_for_feedback_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
  gap: 20px;
}

.login_for_feedback_container button {
  width: 25%;
  background-color: var(--primary-color);
  color: var(--text-color-light);
  border: none;
  font-size: 14px;
  width: 100px;
  height: 30px;
  border-radius: 5px;
}

.login_for_feedback_container button:hover {
  background-color: var(--text-color-light);
  box-shadow: var(--button-box-shadow);
  color: var(--text-color);
}
