@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400&display=swap");

:root {
  --primary-color: #9147ff;
  --secondary-color: #e2e2e6;
  --neutral900: #212134;
  --neutral800: #32324d;
  --neutral700: #4a4a6a;
  --neutral600: #666687;
  --neutral500: #8e8ea9;
  --neutral400: #a5a5ba;
  --neutral300: #c0c0cf;
  --neutral200: #dcdce4;
  --neutral150: #eaeaef;
  --neutral100: #f6f6f9;
  /* --secondary-color: #f2994a; */
  --alternate-color: #eb5757;
  --primary500: #d4f269;
  --primary200: #dcf587;
  --primary100: #eefac3;
  --input: #13171d;
  --input-placeholder: gray;
  --red: #eb0400;
  --bg-color: white;
  --text-color: black;
  --text-color-light: white;
  --text-accent-color: #7519d2;
  --text-hover-color: white;
  --text-hover-color-dark: black;
  --grey-color-light: #aaa;
  --button-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  --info-color: #1976d2;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.swal-container {
  z-index: 10000;
}
