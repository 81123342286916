.app_bar {
  height: 70px !important;
  justify-content: center !important;
  background-color: transparent !important;
  box-shadow: none !important;
  z-index: 1;
}
.search-bar {
  z-index: 1 !important;
}
.notification {
  color: var(--primary-color);
}

.navbar-tab-button {
  margin: 0px 10px;
  text-decoration: none;
  color: white;
}

.MuiMenu-list {
  padding: 0px !important;
}

.MuiMenu-paper {
  background-color: var(--neutral900) !important;
}

.navbar-desktop-dropdown-root {
  z-index: 99999 !important;
}

.navbar-desktop-dropdown-item {
  color: inherit;
}

.navbar-desktop-dropdown-item-container {
  color: var(--text-color) !important;
  background-color: var(--bg-color) !important;
  box-shadow: var(--button-box-shadow);
}

.navbar-desktop-dropdown-item-container:hover {
  background-color: var(--primary-color) !important;
  color: var(--text-hover-color) !important;
}

.three-dots-icon {
  display: none;
}

.three-dots-icon:hover {
  cursor: pointer;
}

.navbar-tab-desktop-button {
  text-transform: none !important;
  padding: 5px 20px !important;
  font-size: 0.8rem !important;
  border-radius: 20px !important;
}

.notification-icon-home {
  margin-left: 10px;
}

.loginSignUpText {
  color: var(--text-color);
  padding: 6px 10px;
}

.loginSignUpText:hover {
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--text-color-light);
}

.notficon {
  color: greenyellow;
}

.avatar {
  margin-right: 30px;
}

.avatar:hover {
  cursor: pointer;
}

.avatarName p {
  margin-top: 10px;
  font-size: 12px;
  margin-left: 10px;
  color: var(--text-color);
}
.profile-box {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.arrow-down {
  margin-top: 5px;
  margin-right: 15px;
  color: var(--neutral600);
}
.polygameLogo {
  height: 75px;
  width: 15% !important;

  background: url("../../images/polygame-logo.svg");
  padding-bottom: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}

.dummyUserLogo {
  padding: 5px 15px 0px 0px;
  cursor: pointer;
}

.badge {
  color: var(--alternate-color) !important;
}
.root {
  left: 0px !important;
}
.notification-tab {
  border-right: 1px solid var(--primary-color);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.notification-tab:hover {
  cursor: pointer;
}

.notification-tab-active {
  background-color: var(--primary-color);
  color: var(--text-color-light);
}

@media (min-width: 1920px) {
  .polygameLogo {
    background-size: contain;
  }
}

@media only screen and (max-width: 1600px) {
  .polygameLogo {
    width: 17% !important;
  }
}

@media only screen and (max-width: 1400px) {
  .polygameLogo {
    width: 20% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .polygameLogo {
    width: 25% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .polygameLogo {
    height: 75px;
  }
  .search-bar {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 990px) {
  .three-dots-icon {
    display: inline;
  }

  .navbar-tab-button {
    display: none;
  }

  .navbar-desktop-tabs-container {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 950px) {
  .polygameLogo {
    width: 30% !important;
  }
}

@media only screen and (max-width: 800px) {
  .polygameLogo {
    width: 40% !important;
    height: 85px;
  }
}

@media only screen and (max-width: 770px) {
  .menu-icon {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  .avatar {
    margin-right: -15px;
  }
  .notification {
    margin-right: 0px !important;
  }
  .polygameLogo {
    height: 75px;
    width: 100% !important;
    background-size: 250px;
  }

  .vector {
    display: none;
  }
  .avatarName {
    display: none;
  }
  .search-bar {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .polygameLogo {
    width: 100% !important;
    background-size: 250px;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 410px) {
  .polygameLogo {
    width: 100% !important;
    background-size: 220px;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 370px) {
  .polygameLogo {
    width: 100% !important;
    background-size: 220px;
    background-repeat: no-repeat;
  }

  .notification-icon-home {
    margin-left: 0px;
  }
}
