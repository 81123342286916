.all-nft-heading {
  font-size: 17px;
  font-weight: 700;
}
.all-nft {
  width: 94.5%;
  height: auto;
  background-color: var(--input);
  border-radius: 8px;
  padding: 18px 17px 12px 17px;
  margin-bottom: 24px;
}
.all-nft-name p:first-child {
  font-size: 14px;
  font-weight: 900;
  /* margin-top: 2px; */
}
.all-nft-name p:nth-child(2) {
  font-size: 10px;
  font-weight: 450;
  /* margin-top: -14px; */
}
.all-nft-desc p {
  font-size: 14px;
  font-weight: 450;
  letter-spacing: 0.4px;
}
.all-nft-desc span {
  color: var(--primary-color);
  cursor: pointer;
}
.likeAndComment {
  display: flex;
  align-items: center;
}
.likeAndComment p {
  font-size: 14px;
  font-weight: 450;
  margin-right: 40px;
}
.likeAndComment img {
  margin-bottom: -6px;
  margin-right: 5px;
  cursor: pointer;
}
.likeAndComment img:nth-child(3) {
  margin-left: 100px;
  margin-top: -4px;
}
.all-nft-comment p:first-child {
  font-size: 14px;
  font-weight: 900;
  /* margin-top: 2px; */
}
.all-nft-comment p:nth-child(2) {
  font-size: 13px;
  font-weight: 450;
  /* margin-top: -14px; */
}
.reply-icon {
  display: flex;
  align-items: center;
}
.reply-icon img {
  margin-left: 50px;
  cursor: pointer;
}
.reply-icon p {
  font-size: 10px;
  font-weight: 450;
  margin-left: 20px !important;
}
.comments-hr {
  background-color: var(--primary-color) !important;
}
.reply-hr {
  background-color: #eaeaea !important;
  margin-left: 50px !important;
}
.reply-input {
  width: 98%;
  height: 40px;
  border: 0.908524px solid #4a4a6a;
  border-radius: 8px;
  margin-top: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px 0 10px;
}
.reply-input input {
  color: var(--text-color-light) !important;
  font-size: 12px !important;
  font-weight: 450 !important;
  width: 950px !important;
}

.reply-input input::placeholder {
  color: var(--text-color-light) !important;
}

.reply-input span {
  margin-top: 3px;
  cursor: pointer;
}
.comment-min {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reply-min {
  font-size: 13px;
}

@media only screen and (max-width: 700px) {
  .all-nft {
    width: 90%;
    /* height: 160px; */
  }
}
