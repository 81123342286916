.delete_modal_container {
  width: 50vw;
  max-width: 600px;
}

.confirm_button {
  background-color: red !important;
  color: var(--text-color-light) !important;
}

.confirm_button:hover {
  background-color: darkred !important;
}

.cancel_button {
  background-color: rgb(36, 232, 36) !important;
  color: var(--text-color-light) !important;
}

.cancel_button:hover {
  background-color: green !important;
}

@media screen and (width < 650px) {
  .delete_modal_container {
    width: 80vw;
  }
}
