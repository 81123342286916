.container {
  width: 100vw;
  max-width: 700px;
  padding: 14px;
  font-size: 16px;
  padding-bottom: 40px;
}

.container > div:first-child {
  display: flex;
  justify-content: flex-end;
}

.container > div:first-child > svg:hover {
  cursor: pointer;
}

.container > div:nth-child(2) {
  font-size: 14px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.table {
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;
  font-family: inherit;
}

.table :is(td, th) {
  border: 1px solid #ddd;
  padding: 8px;
}

.table td:nth-child(3) {
  font-size: 14px;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #ddd;
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--primary-color);
  color: white;
}

@media screen and (width <= 768px) {
  .table {
    font-size: 14px;
  }

  .table td:nth-child(3) {
    font-size: 12px;
  }
}
