.sidebar {
  position: fixed;
  width: 18% !important;
  display: flex;
  height: 100vh;
  overflow: auto;
  margin-top: 50px;
  justify-content: space-evenly;
  align-items: center;
}
.sidebar p {
  font-size: 11px;
  font-weight: 600;
  padding-left: 25px;
  color: #808191;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.sidebar-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sidebar-icons span {
  font-size: 17px;
  letter-spacing: 0.3px;
  font-weight: 600;
  color: var(--text-color) !important;
}
.homeIcon {
  margin: 10px 20px;
  border-radius: 10px;
}
.divider {
  border: 1px solid #34373c !important;
  margin: 10px 0 10px 30px !important;
}
.switch {
  padding-left: 25px;
}
.MuiFormControlLabel-label {
  font-size: 14px !important;
  letter-spacing: 0.3px !important;
  color: #808191 !important;
}
/* .dashboardIcon {
  background: linear-gradient(90.22deg, #0536E3 -10.45%, #00E9ED 142.36%);
  width: 55px;
  height: 50px;
  justify-content: center;
  margin: 10px auto;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.dashboardIcon>img{
  filter: invert(150%);
  -webkit-filter: invert(150%);
} */

.dashboardIconSetting > img {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}
.menu-icon {
  z-index: 1;
}

.activeicon {
  background-color: #c6ff00;
}
.logout {
  background: #f19949;
  margin-left: 15px;
  border-radius: 22px;
  margin-top: 10px;
}

@media only screen and (max-height: 850px) {
  .sidebar {
    padding-top: calc(800px - 80vh) !important;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 800px) {
  .sidebar {
    width: 75% !important;
    height: calc(100% - 50px);
    margin-top: 50px;
    justify-content: flex-start;
    background-color: var(--bg-color) !important;
    box-shadow: var(--button-box-shadow);
  }
  .sidebar-icons span {
    color: var(--text-color) !important;
  }
  .logout {
    background: transparent;
    width: 100%;
  }
  .homeIcon {
    color: #0d1014 !important;
  }
}

@media (min-width: 1920px) {
  .sidebar {
    width: 10% !important;
  }
}
