.opinions-emojis-container {
  margin-top: 15px;
  gap: 30px !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
}

.opinions-emojis-container > div {
  gap: 10px !important;
  font-size: 18px;
}

.opinions-emojis-container > div > svg:hover {
  cursor: pointer;
}

.opinions-emojis-container > div > p {
  min-width: auto !important;
}
