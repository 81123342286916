.chats_main_container {
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.chats_main_inner_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 70%;
}

/* .chats-chat-groups-container {
  border-radius: 5px;
  padding: 20px 0px;
  display: flex;
  gap: 10px;
  color: var(--text-color);
} */

.chats-chat-groups-container {
  margin: 0px auto;
  padding-top: 0px;
  width: 100%;
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.chats-chat-groups-container::-webkit-scrollbar {
  height: 7px;
}

/* Track */
.chats-chat-groups-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.chats-chat-groups-container::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
.chats-chat-groups-container::-webkit-scrollbar-thumb:hover {
  background: var(--text-accent-color);
}

.streamer-box-chats {
  height: 60vh !important;
}

.chats-chat-section-container {
  height: 100%;
  width: 100%;
  margin-top: 30px;
}

.chats-chat-group-item {
  padding: 7px 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-align: center;
  margin: auto;
  text-wrap: nowrap;
}

.chats-chat-group-item:hover {
  color: var(--text-color);
  background-color: var(--text-hover-color);
  cursor: pointer;
}

.chats-chat-group-item-active {
  background-color: var(--primary-color);
  color: var(--text-color-light);
}

.chats-chat-section-inner-container {
  /* overflow: auto;
  height: 70vh; */
  overflow: hidden;
}

#chats-comments-box {
  height: 60vh;
  overflow: auto;
}

#chats-comments-box::-webkit-scrollbar {
  width: 7px;
}

/* Track */
#chats-comments-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
#chats-comments-box::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
#chats-comments-box::-webkit-scrollbar-thumb:hover {
  background: var(--text-accent-color);
}

@media screen and (max-width: 900px) {
  .chats-chat-section-inner-container {
    width: 65%;
  }
}

@media screen and (width <= 768px) {
  .chats-chat-section-inner-container {
    width: 100%;
  }
  /* 
  .chats-chat-groups-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    margin: 0px auto;
    padding-top: 0px;
    width: 80%;
    justify-content: center;
    align-items: center;
  } */

  .chats-chat-group-item {
    font-size: 12px;
  }

  .chats_main_inner_container {
    width: 100%;
  }
  .chats-chat-groups-left-arrow {
    position: absolute;
    left: 5px;
    top: 8px;
  }

  .chats-chat-groups-left-arrow > svg {
    font-size: 32px;
    color: var(--text-color) !important;
  }

  .chats-chat-groups-right-arrow {
    position: absolute;
    right: 1px;
    top: 8px;
  }

  .chats-chat-groups-right-arrow > svg {
    font-size: 32px;
    color: var(--text-color) !important;
  }

  .chats-chat-groups-container {
    position: unset;
  }

  .chats-chat-groups-container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .chats-chat-groups-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .chats-chat-section-container {
    margin-top: 10px;
  }
}
