.video-carousel-container {
  min-height: 350px !important;
  width: 100% !important;
  display: flex;
  color: var(--text-color-light);
}

.videoInfoContainer {
  width: 30%;
  height: 100%;
  background-color: white;
}

.flex-column {
  flex-direction: column !important;
}

.align-start {
  align-items: flex-start !important;
}

.videoInfoContainerHide {
  display: none;
}

.videoInfoliveButton {
  background-color: var(--red);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
}

.videoInfoViewerCount {
  display: flex;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  gap: 2px;
}

.streamer-name-container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single.active
  .slider-right {
  width: 14%;
}

.react-3d-carousel .slider-container .slider-left {
  width: 14%;
}
.react-3d-carousel .slider-container .slider-left div {
  position: relative;
  left: 78% !important;
}

.video-stream-info-container {
  width: 200px;
  position: absolute;
  height: 50px;
  right: -202px;
  z-index: 10;
}

.video-streamer-info-container {
  top: calc(86% - 10px);
  width: 500px;
  right: -502px;
}

.circle {
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 5px;
}

.videoInfoTimeLapse {
  gap: 7px;
  max-width: 100%;
}

.liveStreamTitle {
  font-size: 18px !important;
  font-weight: bold !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px !important;
}

.liveStreamTimeText {
  font-size: 10px !important;
  white-space: nowrap;
}

.video-stream-info-inner-container {
  display: flex;
  padding: 6px 0px 0px 10px;
  gap: 8px;
}

.video-carousel-container:hover {
  cursor: pointer;
}

.videoPlayer {
  width: 100% !important;
  height: 100% !important;
}

@media only screen and (max-width: 1300px) {
  .react-3d-carousel .slider-container .slider-left div {
    left: 76% !important;
  }
}

@media only screen and (max-width: 1100px) {
  .react-3d-carousel .slider-container .slider-left div {
    left: 70% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .react-3d-carousel .slider-container .slider-left div {
    left: 4.1vw !important;
  }
}

@media only screen and (max-width: 850px) {
  .video-carousel-container {
    min-height: 300px !important;
  }

  .video-streamer-info-container {
    width: 400px;
    right: -402px;
    top: calc(83% - 10px);
  }

  .react-3d-carousel .slider-container .slider-left div {
    left: 3.43vw !important;
  }
}

@media only screen and (max-width: 800px) {
  .video-carousel-container {
    min-height: 200px !important;
  }

  .liveStreamTitle {
    font-size: 16px !important;
  }

  .video-streamer-info-container {
    top: calc(81% - 20px);
  }
}

@media only screen and (max-width: 650px) {
  .video-streamer-info-container {
    width: 250px;
    right: -252px;
  }

  .react-3d-carousel .slider-container .slider-left div {
    left: calc(5.3vw - 20px) !important;
  }
}

@media only screen and (max-width: 450px) {
  .video-carousel-container {
    min-height: 180px !important;
  }

  .liveStreamTitle {
    font-size: 14px !important;
  }

  .video-streamer-info-container {
    right: -282px;
    top: 67%;
    width: 290px;
  }

  .videoInfoViewerCount {
    align-items: unset;
  }

  .react-3d-carousel .slider-container .slider-left div {
    left: -1.8vw !important;
  }
}
