.main {
  display: flex;
  gap: 24px;
}

.icon {
  font-size: 48px;
}

.main > img {
  height: 48px;
  width: 48px;
}

.icon:hover {
  cursor: pointer;
  transform: translateY(5px);
}

@media screen and (width < 550px) {
  .main {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
