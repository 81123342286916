.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;
}

.container h2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  margin: 0;
}

.container p {
  margin: 0;
}

.container .yourBid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.container .yourBid p {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.container .yourBid sub {
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0em;
  color: #f2994a;
}

.container .yourBid div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
  background: #212134;
  border-radius: 5px;
}

.container .balance {
  padding: 10px 0px;
}

.container .balance div {
  display: flex;
  justify-content: space-between;
}

.container .balance div p:nth-child(1) {
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0em;
}

.container .balance div p:nth-child(2) {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
