.container {
  height: 100vh;
  width: 100vw;
  max-width: 600px;
  padding: 10px;
}

.comments_container {
  height: calc(86% - 40px);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 20px;
  margin: 20px 0px;
  overflow: auto;
}

.comments_container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.comments_container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
.comments_container::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
.comments_container::-webkit-scrollbar-thumb:hover {
  background: var(--text-accent-color);
}

.comment_container {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  font-size: 14px;
  text-align: justify;
}

.comment_container > img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.comment_description_container {
  display: flex;
  flex-direction: column;
}

.comment_description_container > div {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.comment_info_container {
  display: flex;
  gap: 5px;
  color: var(--grey-color-light);
  font-size: 12px;
}

.comment_info_container > :is(p:nth-child(2), p:nth-child(3)):hover {
  cursor: pointer;
  color: var(--text-color);
}

.post_comment_container {
  width: 100%;
  position: relative;
  height: 6%;
  display: flex;
  border-top: 1px solid var(--grey-color-light);
}

.reply_comment_container {
  position: absolute;
  width: 100%;
  height: 50px;
  top: -51px;
  background-color: lightgray;
}

.reply_comment_container > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0px 15px 0px 20px;
}

.reply_comment_container > div > p {
  font-size: 16px;
}

.close_icon_container > svg {
  font-size: 24px;
  color: var(--text-color);
}

.close_icon_container > svg:hover {
  cursor: pointer;
  opacity: 0.5;
}

.post_comment_input_container {
  display: flex;
  width: 100%;
  margin-left: 20px;
}

.post_comment_input_container > input {
  background-color: var(--bg-color) !important;
  border: none !important;
  color: var(--text-color) !important;
  width: 80% !important;
  padding: 0px;
}

.post_comment_input_container > input:focus {
  outline: none !important;
}

.cross_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8%;
  border-bottom: 1px solid var(--grey-color-light);
}

.cross_container > div > svg:hover {
  opacity: 0.5;
  cursor: pointer;
}

.posted_by_container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.posted_by_container > img {
  width: 28px;
  height: 28px;
}

.post_button {
  width: 20%;
  border: none;
  background-color: transparent;
  color: var(--grey-color-light);
  font-weight: bold;
  font-size: 16px;
}

.post_button_active {
  color: var(--primary-color);
}

.post_button_active:hover {
  cursor: pointer;
  color: var(--text-accent-color);
}

.load_comments_container {
  display: flex;
  justify-content: center;
}

.load_comments_container > svg {
  font-size: 28px;
}

.load_comments_container > svg:hover {
  cursor: pointer;
  opacity: 0.5;
}

.reply_comments_container {
  display: flex;
  flex-direction: column;
  margin-top: 15px !important;
  gap: 15px !important;
  padding-left: 40px;
}

.no_comments_container {
  display: flex;
  height: 90%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.no_comments_header {
  font-size: 32px;
  font-weight: bold;
}

.no_comments_text {
  color: var(--grey-color-light);
  font-size: 16px;
}

/*
  For mobile use svh for heights
*/

@media screen and (width < 768px) {
  .container {
    height: 100svh;
  }
}

@media screen and (width < 550px) {
  .reply_comments_container {
    padding-left: 0px;
  }

  .no_comments_header {
    font-size: 28px;
  }

  .no_comments_text {
    font-size: 14px;
  }
}
