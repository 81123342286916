.createnewnfmargin {
  /* margin-left: 270px !important; */
  /* margin-top: 30px !important; */
  /* margin-right: 70px !important; */
  margin-left: 10%;
}
.rowflex {
  display: flex;
  flex-direction: row;
}
.imagesize {
  width: 40px !important;
  height: 40px !important;
  margin-left: 4px !important;
  margin-top: 20px;
}
.titlesize {
  font-weight: 450;
  font-style: normal;
  font-size: 24px;
  color: #c6ff00 !important;
  margin-left: 10px !important;
}
.borderline {
  border: 0.5px solid #dcf587;
}
.uploadfiletitle {
  font-weight: 500;
  font-style: normal;
  font-size: 14.57px;
  line-height: 48.43px;
}
.uploadfilediv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 31px 75px;
  position: static;
  width: 100%;
  height: 284px;
  left: 0px;

  margin-top: -10px !important;
  background: #13171d;
  /* Neutral 700 */

  border: 1px dashed #4a4a6a;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 12px 0px;
}
.uploadfilecontent1 {
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 15.18px;
  color: #a5a5ba !important;
}
.createfilebtn {
  font-weight: 400;
  font-style: normal;
  font-size: 12.09px;
  line-height: 18.14px;
  background: #13171d;
  letter-spacing: 0.201563px;
  text-transform: capitalize;
  color: #eefac3;
  border: 2px solid #eefac3;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  margin-top: 4px;
}
.putonmarket {
  position: static;
  left: 0%;
  right: 45.09%;
  top: 0%;
  bottom: 59.87%;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 30px !important;
  /* identical to box height */

  /* Primary 600 */

  color: #c6ff00;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 7.28491px 0px;
}
.sectionrowflex {
  display: flex;
  flex-direction: row;
}
.sectiondivsize {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.loreamtext {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-top: -5px;
  /* Neutral 0 */

  color: #ffffff;
}
.pricerowflex {
  display: flex;
  flex-direction: row;
  width: auto;
}
.checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 110px;
  margin-left: 20px;
  background-color: var(--primary-color);
  border-radius: 8px;
  color: var(--neutral900);
  padding: 15px 10px 5px 10px;
  cursor: pointer;
  margin-bottom: 70px !important;
}

.pricediv {
  background: #c6ff00;
  border-radius: 8px;
  padding: 10px 30px;
  text-align: center;
}
.pricetext {
  color: black !important;
}
.blackbg {
  background-color: black;
}
.bidsdiv {
  border: 2px solid #c6ff00;
  border-radius: 8px;
  padding: 10px;
  padding-top: 20px;
  margin-left: 40px;
}
.bidstimetext {
  margin-top: 20px;
  text-align: center;
}
.priceimgalign {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.pricetext {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  /* Neutral 900 */
  color: #212134;
}
.pricetextmargin {
  margin-top: -40px;
}
.inputrow {
  display: flex;
  flex-direction: row;
}

.inputfieldprice {
  background-color: #13171d;
  border: 2px solid #13171d;
  padding: 10px;
  width: 80%;
}
.inputfieldpricespan {
  background-color: #13171d;
  border: 2px solid #13171d;
  padding: 10px;
  width: 20%;
  display: flex;
  justify-content: end;
  color: #ff733a !important;
}
.maincolor {
  color: #c6ff00 !important;
  margin-left: 10px;
}
.unlockswitchrow {
  display: flex;
  flex-direction: row;
}
.greencolor {
  color: #c6ff00 !important ;
}
.unlockmintingtext {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  /* Primary 600 */

  color: #c6ff00;
}
.formbg {
  background-color: #13171d;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin-top: 5px;
  border: 2px solid #13171d;
}
.formpadding {
  padding: 4px;
  margin-top: -40px;
  width: 100%;
}
.labelmargin {
  margin-top: 10px !important;
}
.createnftbtnrow {
  display: flex;
  flex-direction: row;
  justify-items: start;
}
.justifybuttons {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}

.cancelbutton {
  width: 100%;
  border-radius: 10px;
  border: 2px solid var(--primary-color);
  background: var(--bg-color);
  color: var(--primary-color) !important;
  padding: 3%;
  justify-content: flex-start;
  margin-left: 0% !important;
  cursor: pointer;
  font-weight: bolder;
}
.cancelbutton:hover {
  background-color: #3a3636;
}
.createnftbutton {
  width: 100%;
  padding: 3%;
  border-radius: 10px;
  font-weight: bold;
  border: 2px solid var(--primary-color);
  background: var(--primary-color);
  color: var(--text-color-light) !important;
  margin-left: 5%;
  cursor: pointer;
}
.createnftbutton:hover {
  transform: translateY(-5px);
  box-shadow: 0px 2px 5px var(--primary-color);
}

@media only screen and (max-width: 1199px) {
  .createnewnfmargin {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}
@media screen and (max-width: 800px) {
  .sectionrowflex {
    display: flex;
    flex-direction: column;
  }

  .sectiondivsize {
    width: 90%;
  }
  .formpadding {
    padding: 0%;
    margin-top: -40px;
  }
}
