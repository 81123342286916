.container {
  padding: 20px;
  width: 90vw;
  max-width: 450px;
}

.header_container {
  display: flex;
  justify-content: space-between;
}

.header_container > p {
  font-size: 18px;
  font-weight: bold;
}

.header_container > div > svg:hover {
  cursor: pointer;
}

.content_container {
  margin-top: 20px;
  text-align: center;
}

.content_container > p:nth-child(2) {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.button_container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button {
  background-color: var(--primary-color);
  color: var(--text-color-light);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 14px;
}

.button:hover {
  cursor: pointer;
  opacity: 0.9;
}
