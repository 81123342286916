.home_top_heading {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.home_top_heading h2 {
  margin: 0;
  color: var(--primary-color);
}

.home_top_heading > h2 > span:first-child,
.home_top_heading > h2 > span:nth-child(3) {
  color: teal;
}

.home_top_heading > h2 > span:nth-child(2) {
  color: #f2994a;
}
