.home-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.home-top-heading {
  height: 350px;
}

.home-top-heading-with-text {
  height: 400px;
}

.streams-number {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.home-top-heading p:nth-child(2) span:nth-child(1) {
  color: var(--primary-color);
}
.home-top-heading p:nth-child(2) span:nth-child(2) {
  color: var(--secondary-color);
}
.home-top-heading p:nth-child(2) span:nth-child(3) {
  color: var(--primary-color);
}

.games-card .games-header {
  display: flex;
  justify-content: space-between;
  color: var(--text-color);
}

.games-card {
  margin-top: 40px;
}

.react-multiple-carousel__arrow {
  z-index: 1;
}

.games-header {
  margin-top: 10px;
}

.games-header p {
  font-size: 24px;
  font-weight: 500;
}

.games-card .games-header p:first-child {
  font-size: 24px;
  font-weight: 500;
  line-height: 15px;
}

.games-card .games-header p:nth-child(2) {
  letter-spacing: 0.3px;
  cursor: pointer;
}

.games-card section {
  width: 500px;
}

.react-3d-carousel {
  width: 100% !important;
  height: 0px !important;
  padding-bottom: 30% !important;
}

.react-3d-carousel .slider-container .slider-left div,
.react-3d-carousel .slider-container .slider-right div {
  margin-top: 0px !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-items: center;
  border: none !important;
  background-color: black;
}

.react-3d-carousel .slider-container {
  margin: 0px !important;
  width: 100% !important;
  left: 0% !important;
  background-color: var(--primary-color);
}

.react-3d-carousel .slider-container img {
  border-radius: 15px;
}

.react-3d-carousel .slider-container video {
  /* border-radius: 15px; */
  /* width: 80% !important; */
  object-fit: cover;
}

div.slideImgContainer {
  position: relative;
  display: flex;
  cursor: pointer;
}
div.slideImgContainer p.slideTitle {
  text-align: center;
  transform: translate(0px, -74px);
  height: 0px;
}
.gameInfo {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: flex-end;
  margin-top: 10px;
  margin-left: 30%;
}
.gameTitle {
  border-radius: 8px;
  color: white;
  font-size: 14px;
  margin: 5px;
  width: max-content;
  padding: 5px 10px;
}
div.slideImgContainer:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96px;
  height: 96px;
  background: url("/src/images/playbutton.svg");
  background-size: cover;
  content: "";
}
div.slideImgContainer:before {
  position: absolute;
  /* top: 50%; left: 50%; */
  /* transform: translate(-50%,-50%); */
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  background-size: cover;
  content: "";
  border-radius: 15px;
}
.sliderImg {
  height: 300px;
  width: auto;
}

.row2 {
  height: 25%;
  box-sizing: border-box;
  width: 100%;
}
.row2gr {
  padding-left: 15px;
}
.boxsimage {
  margin-right: 5px;
}

.imageamount {
  height: 20px;
  width: 20px;
  color: var(--text-color);
}
.sname {
  height: 40px;
  font-weight: 600;
  text-align: left;
  color: var(--text-accent-color);
  font-size: 16px;
}
.sname p {
  line-height: 25px;
}
.amount {
  width: 100%;
  height: 25px;
  text-align: center;
  padding-left: 4px;
  font-size: 35px;
}
.amount p {
  font-size: large;
}
.games-card .card2 {
  width: 20%;
  /* min-width: 150px;  */
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.games-card .card2mobile {
  width: 48%;
  /* min-width: 150px;  */
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px;
  box-shadow: 0 2px 5px var(--secondary-color);
  color: var(--text-color);
  background-color: var(--secondary-color);

  justify-content: center;
  border-radius: 25px;
  height: 130px;
  padding: 5%;
  width: 100%;
}
.imagepgem {
  height: 10px;
  width: 10px;
}
.imageuser {
  height: 10px;
  width: 10px;
}
.imagevector {
  height: 10px;
  width: 10px;
}

.tencentPlayer {
  width: 50vw;
  height: 300px;
}

.streams-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .games-card {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 800px) {
  .home-top-heading {
    display: flex;
    flex-direction: column;
    height: 200px;
  }

  .home-top-heading-with-text {
    display: flex;
    flex-direction: column;
    height: 250px;
  }
  .home-main {
    gap: 20px;
  }
  .sliderImg {
    height: 150px;
    width: 100%;
  }

  .tencentPlayer {
    width: 80vw;
    height: 200px;
  }
}

@media only screen and (max-width: 550px) {
  .tencentPlayer {
    width: 65vw;
    height: 180px;
  }

  .games-header p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 400px) {
  .tencentPlayer {
    width: 65vw;
    height: 150px;
  }
}

#start-screen-cont .polygame-logo {
  margin: 15px;
}
#start-screen-cont .main-grid {
  margin-top: 6%;
}
#start-screen-cont .card1 {
  display: flex !important ;
  align-items: center !important;
  justify-content: center !important;
  margin-left: 5% !important;
}
#start-screen-cont .streamer-main-btn {
  margin-left: 0px !important;
}
#start-screen-cont .heading {
  color: var(--primary-color);
  display: flex;
  flex-direction: column !important;
  font-size: 12px;
  text-align: center;
  justify-content: center !important;
  margin-left: -10% !important;
}
#start-screen-cont .heading h1 {
  margin-bottom: 30px;
}
#start-screen-cont .heading div:nth-child(2) {
  flex-wrap: wrap;
  display: flex;
  margin-left: 30px;
  justify-content: center;
}
#start-screen-cont .become_streamer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 110px;
  background-color: var(--primary-color);
  border-radius: 8px;
  color: var(--neutral900);
  padding: 15px 10px 5px 10px;
  cursor: pointer;
  margin-bottom: 70px !important;
}
#start-screen-cont .become_streamer2 {
  background-color: transparent !important;
  color: #fff;
  border: 1px solid var(--primary-color);
}
#start-screen-cont .become_streamer p {
  font-size: 14px;
  font-weight: 600;
}
#start-screen-cont .watch-streamer-heading p {
  margin-top: 5px;
}
#start-screen-cont .watch-streamer-heading img {
  margin-top: -10px;
}
#start-screen-cont .watch_streamer {
  height: 140px;
  width: 120px;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 10px;
}
#start-screen-cont .lets_begin button {
  text-transform: unset;
  text-align: center;
  border-radius: 5px;
  height: 45px;
  width: 190px;
  color: black;
  background-color: var(--primary-color);
}
#start-screen-cont .lets_begin button:hover {
  background-color: var(--primary-color);
}
#start-screen-cont .text-crypto-game {
  margin-top: 60px;
  font-size: 12px;
  font-weight: 300;
  color: #ffffff;
}
#start-screen-cont .loginLink {
  display: inline;
  margin-top: 20px;
  color: var(--primary-color);
}
#start-screen-cont .imgCover {
  width: 75%;
}
@media only screen and (max-width: 899px) {
  #start-screen-cont .imgCover {
    width: 80%;
  }
  #start-screen-cont .heading {
    margin-left: 0px !important;
  }
}
