.poly-community-item {
  width: 100%;
  height: 350px;
  border-radius: 15px;
  background-color: #13171d;
  background-size: cover;
  color: var(--text-color-light);
}

.poly-community-item img,
.poly-community-item video {
  width: 100%;
  height: 78%;
  border-radius: 15px;
}

.poly-community-item > div {
  padding: 10px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.poly-community-item > div > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.poly-community-item > div > div:hover {
  cursor: pointer;
}

.poly-community-item > div > p {
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.poly-community-item svg {
  color: var(--text-color-light);
  font-size: 32px;
}

.polycommunity_add_post_container {
  display: flex;
  gap: 5px;
}

.polycommunity_add_post_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--text-color-light);
  border: none;
  font-size: 14px;
}

.polycommunity_add_post_button:hover {
  cursor: pointer;
  background-color: var(--text-color-light);
  box-shadow: var(--button-box-shadow);
  color: var(--text-color);
}

.poly-community-posted-by-container {
  display: flex;
  justify-content: center !important;
  padding: 4px 10px 0px 10px !important;
}

.polyCommunity-load-more-button-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.polyCommunity-load-more-button {
  gap: 4px;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--text-color-light);
  border: none;
  font-size: 14px;
}

.polyCommunity-load-more-button:hover {
  cursor: pointer;
  background-color: var(--text-color-light);
  box-shadow: var(--button-box-shadow);
  color: var(--text-color);
}

.poly_community_end_post_container {
  width: 100%;
  margin-top: 8px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.poly_community_end_post_container > p {
  font-size: 14px;
}

.poly_community_end_post_container > svg {
  color: #1976d2;
  font-size: 28px;
}

@media only screen and (width <= 750px) {
  .poly_community_end_post_container {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 550px) {
  .poly-community-item svg {
    font-size: 24px;
  }

  .poly-community-item > div {
    display: flex;
    align-items: center;
  }
}
