.low_comments_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.no_comments_container {
  display: flex;
  height: 88%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.no_comments_text {
  color: var(--grey-color-light);
  font-size: 14px;
}
