.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  width: 100%;
}

.container label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.container input {
  background: #13171d !important;
  border-radius: 8px;
  height: 45px;
  border: none;
  width: 100%;
  color: white;
  padding: 0px 10px;
}

.container p {
  color: red;
  font-size: 0.7rem;
  margin: 0;
}
