.sidebarcards_main_div {
  position: fixed;
  right:0px
}

.sidebarCard_main {
  width: 200px;
  height: 86.7px;
  background-color: var(--input);
  border-radius: 8px;
  position: relative;
  padding-left: 10px;
  padding-top: 5px;
  margin-bottom: 10px;
}
.youMayLike {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}
.sidebarCard_main > img:first-child {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.cardAvatar {
  margin-top: 8px;
  width: 60px !important;
  height: 60px !important;
}
.card_details p:first-child {
  font-size: 10.38px;
  font-weight: 600;
  letter-spacing: 0.17px;
  line-height: 15.57px;
  color: var(--neutral100);
  margin: 5px;
}
.card_details p:nth-child(2) {
  font-size: 7px;
  letter-spacing: 0.17px;
  line-height: 10.38px;
  color: var(--neutral400);
  margin: 5px;
}
.card_details button {
  width: 87px;
  height: 20px;
  font-size: 10.38px;
  letter-spacing: 0.17px;
  text-transform: capitalize;
  background-color: #b7e514;
  color: var(--input);
  border-radius: 22px;
}
.card_details button:hover {
  background-color: #fff;
}
.card_details2 p:first-child {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 20px;
  margin: 5px;
}
.card_details2 p:nth-child(2) {
  font-size: 8.8px;
  letter-spacing: 0.34px;
  line-height: 10px;
  color: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin: 5px;
}
.card_details2 p:nth-child(3) {
  font-size: 8.2px;
  letter-spacing: 0.34px;
  color: #808191;
  margin: 5px;
}
.link_style{
  text-decoration: none;
}
@media only screen and (max-width: 899px) {
  .mobile_card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* margin-left: 8px; */
  }
  /* .youMayLike {
    margin-left: 20px;
  }
  .sidebarCard-main {
    margin-left: 10px;
  } */
  .sidebarCard_main {
    width: 95%;
    height: 86.7px;
  }
}

@media only screen and (max-width: 850px) {
  .sidebarcards_main_div {
    display: none;
  }
}
