.container {
  height: 10px;
  width: 100%;
  background-color: var(--primary-color);
  position: absolute;
  bottom: 70px;
  position: absolute;
  z-index: 100000;
  position: fixed;
}

.innerContainer {
  position: absolute;
  top: -30px;
  left: 40px;
}

.line1 {
  position: absolute;
  height: 3px;
  width: 25px;
  background-color: var(--primary-color);
  top: 22px;
  transform: rotate(140deg);
}

.line2 {
  position: absolute;
  height: 3px;
  width: 25px;
  background-color: var(--primary-color);
  top: 22px;
  left: 50px;
  transform: rotate(220deg);
}
.box {
  position: absolute;
  height: 20px;
  width: 40px;
  background-color: var(--primary-color);
  top: 12px;
  left: 18px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.fillBox1 {
  position: absolute;
  height: 35px;
  width: 10px;
  background-color: var(--primary-color);
  top: 8px;
  left: 20px;
  transform: rotate(270deg);
  border-top-right-radius: 100px;
}
.fillBox2 {
  position: absolute;
  height: 35px;
  width: 10px;
  background-color: var(--primary-color);
  top: 8px;
  left: 45px;
  transform: rotate(270deg);
  border-bottom-right-radius: 100px;
}

.iconContainer {
  left: 27px;
  position: absolute;
  top: 12px;
}

.iconContainer > svg {
  color: var(--text-color-light) !important;
  font-size: 24px;
}

.touchContainer {
  position: absolute;
  top: 5px;
  left: 0px;
  height: 35px;
  width: 70px;
  z-index: 2px;
}
