:root {
  --white-color: #fff;
  --blue-color: #4070f4;
  --grey-color: #707070;
}

/* sidebar */

.sidebar-container {
  transition: all 0.5s ease !important;
}

.item {
  margin-bottom: 5px;
}

.item-active {
  background-color: var(--primary-color);
  color: black;
}

.navlink_icon-active {
  color: black;
}

.navlink {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-left: 5px;
}

.desktop-sidebar {
  background-color: var(--bg-color);
  width: 260px !important;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding: 80px 20px;
  z-index: 100;
  overflow-y: scroll;
  box-shadow: 0 0 2px var(--grey-color-light);
  transition: all 0.5s ease;
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
  overflow: auto;
}
.desktop-sidebar.close {
  padding: 60px 0;
  width: 80px !important;
}
.desktop-sidebar::-webkit-scrollbar {
  display: none;
}
.menu_content {
  position: relative;
}
.menu_title {
  margin: 15px 0;
  padding: 0 20px;
  font-size: 18px;
}
.desktop-sidebar.close .menu_title {
  padding: 6px 30px;
}
.menu_title::before {
  color: var(--text-color);
  white-space: nowrap;
}
.menu_dahsboard::before {
  content: "Account";
  font-size: 16px;
  font-weight: bold;
}
.menu_editor::before {
  content: "Account";
}
.menu_setting::before {
  content: "Setting";
}
.desktop-sidebar.close .menu_title::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 18px;
  border-radius: 12px;
  background: var(--text-color);
}
.menu_items {
  padding: 0;
  list-style: none;
}
.navlink_icon {
  position: relative;
  font-size: 32px;
  min-width: 50px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 6px;
  height: 32px;
}
.navlink_icon::before {
  content: "";
  position: absolute;
  height: 100%;
  width: calc(100% + 100px);
  left: -20px;
}
.navlink_icon:hover {
  background: var(--primary-color);
}

/* .navlink_icon:hover svg {
  color: var(--text-color-light);
} */
.desktop-sidebar .nav_link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 15px;
  border-radius: 8px;
  text-decoration: none;
  color: var(--text-color);
  white-space: nowrap;
}
.desktop-sidebar.close .navlink {
  display: none;
}
.nav_link:hover {
  color: var(--text-hover-color);
  background: var(--primary-color);
  cursor: pointer;
}

.nav_link:hover svg {
  color: var(--text-color-light);
}

.desktop-sidebar.close .nav_link:hover {
  background: var(--white-color);
}
.submenu_item {
  cursor: pointer;
}
.submenu {
  display: none;
}
.submenu_item .arrow-left {
  position: absolute;
  right: 10px;
  display: inline-block;
  margin-right: auto;
}
.desktop-sidebar.close .submenu {
  display: none;
}
.show_submenu ~ .submenu {
  display: block;
}
.show_submenu .arrow-left {
  transform: rotate(90deg);
}
.submenu .sublink {
  padding: 15px 15px 15px 52px;
}
.bottom_content {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 260px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.bottom {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: space-around;
  padding: 18px 0;
  text-align: center;
  width: 100%;
  color: var(--grey-color);
  border-top: 1px solid var(--grey-color-light);
  background-color: var(--white-color);
}
.bottom i {
  font-size: 20px;
}
.bottom span {
  font-size: 18px;
}
.desktop-sidebar.close .bottom_content {
  width: 50px;
  left: 15px;
}
.desktop-sidebar.close .bottom span {
  display: none;
}
.desktop-sidebar.hoverable .collapse_sidebar {
  display: none;
}
#sidebarOpen {
  display: none;
}
@media screen and (max-width: 768px) {
  #sidebarOpen {
    font-size: 25px;
    display: block;
    margin-right: 10px;
    cursor: pointer;
    color: var(--grey-color);
  }
  .desktop-sidebar.close {
    left: -100%;
  }
  .search_bar {
    display: none;
  }
  .desktop-sidebar.close .bottom_content {
    left: -100%;
  }
}
