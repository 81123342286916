.line-chart-toolbar {
  margin-left: 17px;
}

.line_chart_button {
  font-size: 12px;
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid white;
}

.line_chart_button:hover {
  cursor: pointer;
}

.chart_button_active {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--text-color-light);
}

.exchange_chart_component_no_trade_found_countainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
