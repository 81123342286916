#chatbox_main_container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  height: 75vh;
  overflow-x: hidden;
  width: 30vw;
  background-color: #1a2028;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #c6ff00;
  display: flex;
  flex-direction: column;
  z-index: 10000;
}

#chatbox_top_bar {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#chatbox_top_bar > div {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
}

#chatbox_top_bar > div > span {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 10px;
}

#chatbox_top_bar > div > span > h5 {
  margin: 0px;
  font-size: 1em;
}
#chatbox_top_bar > div > span > p {
  font-size: 0.7em;
  color: grey;
  margin: 0px;
}

#chatbox_top_bar > div > div {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin: 0px;
  background-color: grey;
}

#chatbox_top_bar > button {
  color: #c6ff00;
  border: none;
  background-color: transparent;
  font-size: 1em;
}

#chatbox_top_bar > button:focus,
#chatbox_top_bar > button:active {
  outline: none;
}

#chatbox_top_bar > button:hover {
  cursor: pointer;
}

#chats_container {
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.outgoing_msg {
  max-width: 80%;
  border-radius: 10px;
  padding: 10px;
  color: black;
  background-color: #eefac3;
  font-size: 0.8em;
  float: right;
  border-bottom-right-radius: 0px;
  margin: 10px 0px;
}

.incoming_msg {
  max-width: 80%;
  border-radius: 10px;
  padding: 10px;
  color: white;
  background-color: #4a4a6a;
  font-size: 0.8em;
  float: left;
  border-bottom-left-radius: 0px;
  margin: 10px 0px;
}

.outgoing_msg_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.incoming_msg_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#msg_input_box {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

#msg_input_box > textarea {
  flex-grow: 1;
  border-radius: 10px;
  border: none;
  font-size: 1em;
  padding: 10px;
}

#msg_input_box > textarea:focus,
#msg_input_box > textarea:active {
  outline: none;
}

#msg_input_box > button {
  background-color: transparent;
  border: none;
  color: white;
  background-color: #ff7742;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 5px;
}

@media only screen and (max-width: 1000px) {
  #chatbox_main_container {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  #chatbox_main_container {
    width: 90%;
  }
}
