.main {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.container {
  padding: 0;
  border: 0;
  width: 40%;
}
.section {
  background: #fff;
  position: relative;
  border-radius: 2px;
  margin: 10px 0;
  padding: 0;
  border: 1px solid #dbdbdb;
}
.section .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section .header .user {
  margin: 5px;
  padding: 3px 7px;
  border: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.section .header .user > img {
  width: 28px;
  height: 28px;
}

.section .header .param {
  cursor: pointer;
  margin: 6px;
  padding: 6px;
  border: 0;
  border-radius: 4px;
}

.section .header .param:hover {
  background: #000;
}
.section .img_container :is(img, video) {
  width: 100%;
  padding: 0px 1px;
  height: 450px;
  border-top: 1px solid #dbdbdb;
  border-radius: 3px;
}

.section .like-checkbox {
  display: none;
}
.section .toolbar {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.section .toolbar .like,
.section .toolbar .comment,
.section .toolbar .transfer,
.section .toolbar .signet {
  margin: 8px;
  padding: 0;
  border: 0;
}

.section .toolbar .like > svg,
.header > div > svg {
  font-size: 28px;
}

.section .toolbar :is(.like, .comment, .transfer):hover,
.header > div > svg:hover {
  cursor: pointer;
  opacity: 0.5;
}

.section .toolbar .signet {
  position: absolute;
  right: 0;
}
.section .description {
  margin: 6px;
  padding: 6px;
  border: 0;
  font-size: 16px;
}
.section .description strong {
  font-weight: bold;
  font-size: 15px;
}
.check-like {
  animation-duration: 0.2s;
  animation-name: like;
  fill: #1f81ba;
}
.check-path {
  fill: #1f81ba;
  background: #1f81ba;
}

.likes_count {
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
}

.comments_count {
  margin: 6px 0px 0px 10px;
  color: rgb(138, 138, 138);
  font-size: 14px;
}

.comments_count:hover {
  cursor: pointer;
}

@keyframes like {
  from {
    transform: scale(1);
  }
  75% {
    transform: scale(1.4);
  }
  to {
    transform: scale(1);
  }
}

@media screen and (width < 1100px) {
  .container {
    width: 60% !important;
  }
}

@media screen and (width < 900px) {
  .container {
    width: 80% !important;
  }
}

@media screen and (width < 550px) {
  .main {
    padding-left: 20px;
  }
  .container {
    width: 95% !important;
  }

  .section .img_container :is(img, video) {
    height: 250px;
  }
}
