.container {
  display: flex;
  justify-content: center;
}

.inner_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.inner_container > p {
  font-weight: bold;
  font-size: 20px;
}

.inner_container > p:last-child {
  font-weight: normal;
  color: var(--grey-color-light);
  font-size: 14px;
}

.inner_container > a {
  color: var(--primary-color);
  font-size: 20px;
}

@media screen and (width <= 550px) {
  .inner_container > p {
    font-weight: bold;
    font-size: 14px;
  }

  .inner_container > a {
    font-size: 16px;
  }
}
