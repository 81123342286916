.nft_btn2 button:first-child {
  background-color: var(--primary-color);
  font-weight: 500;
  padding: 8px 12px;
  color: var(--text-color-light);
  font-size: 11px;
}
.nft_btn2 button:nth-child(2) {
  font-weight: 600;
  padding: 8px 12px;
  background-color: var(--secondary-color);
  font-size: 11px;
}
.card_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  color: white;
}
.desc_status {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.meka_comments {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.meka_comments > p:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.meka_comments > p:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #dcf587;
}

.nft_card_btn {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.streamercard_main {
  overflow: hidden !important;
  height: max-content;
}
.nft_card_img {
  width: 100%;
  min-height: 190px;
}
@media only screen and (max-width: 700px) {
  .nft_btn2 button:first-child {
    font-weight: 800;
    padding: 10px 5px;
    color: black;
    font-size: 10px;
  }
  .nft_btn2 button:nth-child(2) {
    padding: 10px 5px;
    background-color: var(--secondary-color);
    font-size: 10px;
  }
}
