.polycoins-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 0px;
  color: var(--text-color);
}
.balance h6 {
  font-size: 3rem;
}

#polycoins-w2earn-table {
  border-collapse: collapse;
  width: 100%;
}

#polycoins-w2earn-table td,
#polycoins-w2earn-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#polycoins-w2earn-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#polycoins-w2earn-table tr:hover {
  background-color: #ddd;
}

#polycoins-w2earn-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--primary-color);
  color: white;
}
.nft_wallet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px;
  margin-left: 0px;
}
.nft_wallet .walletButton {
  background-color: var(--primary-color);
  padding: 7px 10px;
  border-radius: 10px;
  margin-right: 15px;
  color: var(--text-color-light);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.nft_wallet .walletButton:hover {
  color: var(--text-color);
  background-color: var(--text-hover-color);
}
.polycoins-main h6,
p {
  margin: 0;
}

.logo {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 20px;
}
.setting {
  display: flex;
  color: #c6ff00;
  margin-top: 40px;
  align-items: center;
}
.text {
  margin-left: 20px;
  font-size: 20px;
}
.vl {
  border-bottom: 1px solid #dcf587;
  width: 78%;
  height: 12px;
  margin-top: -20px;
}

.polyconis {
  position: relative;
  background-color: blue;
  height: 300px;
}

.polyconis img {
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: var(--bg-color);
}

.polyconis .polyconis-content {
  padding: 30px;
  position: relative;
  z-index: 10 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--text-color);
  height: 100%;
}
.pairtype {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 600;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}
.details {
  width: 100%;
  margin-bottom: 10px;
}
.vline {
  border-bottom: 1px solid var(--primary-color);
  width: 100%;
  height: 12px;
  margin-top: -30px;
}
.Pairtype p {
  margin-top: 10%;
}
.coin_content {
  justify-content: space-between;
  display: flex;
  width: 100%;
}
.fantoken img {
  margin-left: 10px;
}

.balance {
  display: flex;
  align-items: center;
  font-size: 2rem;
  gap: 20px;
}

.balance > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.boxmain_coins {
  overflow: hidden;
  box-shadow: 0 2px 5px black;
  border-radius: 15px;
  border: 2px;
  display: inline;
  margin: 10px;
  border-color: black;
  width: 100%;
  justify-content: space-between;
  height: 80px;
  position: relative;
  left: 20px;
  background-color: #13171d;
  padding: auto;
}
.Box_card {
  display: flex;
  width: 100%;
  height: 70px;
  border: 2px solid black;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 25px;
  background-color: #13171d;
  color: var(--text-color-light);
}

.Box {
  display: flex;
  width: 100%;
  height: 70px;
  border: 2px solid #c6ff00;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.img {
  width: 30px;
  height: 28px;
}
.Box p {
  color: #c6ff00;
}
.login_icon {
  width: 35px;
  height: 23px;
}
.polycoins_image_mobile {
  display: none;
}
.polycoins_image {
  display: block;
}

.watch_to_earn_calculations_container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.watch_to_earn_calculations_container > p {
  font-size: 14px;
  color: gray;
}

.watch_to_earn_calculations_container > div > svg {
  color: var(--info-color);
  font-size: 24px;
}

.watch_to_earn_calculations_container > div > svg:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media only screen and (max-width: 899px) {
  .polycoins_image_mobile {
    display: block;
  }
  .polycoins_image {
    display: none;
  }
  .balance {
    flex-wrap: wrap;
    gap: 0px;
  }
}
