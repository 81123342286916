.container {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tabs_container {
  display: flex;
}

.tabs_container div {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-right: 1px solid var(--text-color);
  border-bottom: 1px solid var(--text-color);
}

.tab_active {
  background-color: var(--primary-color);
  color: var(--text-color-light);
  border-right: 1px solid var(--primary-color) !important;
  border-bottom: 1px solid var(--primary-color) !important;
}

.cross_container {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.cross_icon_container {
  background-color: #e1e8ee;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.cross_icon_container > svg {
  font-size: 24px;
  color: var(--text-color);
}
