.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 3.7%;
  box-sizing: border-box;
  overflow: hidden;
}

.content {
  display: flex;
  flex: 1;
  /* max-width: 1050px; */
  width: 100%;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}
