.main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  width: 100%;
  color: var(--text-color);
}

.chart_tabs_container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0px 0px 20px 17px;
  gap: 5px;
  color: var(--text-color);
}

.chart_tab {
  font-size: 12px;
  padding: 5px 10px;
  font-weight: bold;
  border: unset;
  border-radius: 5px;
}

.chart_tab:hover {
  cursor: pointer;
}

.chart_tab_active {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--text-color-light);
}

.money_exchange {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
}

.exchange_main {
  display: flex;
  justify-content: space-between;
}

.details {
  width: 100%;
  margin-bottom: 10px;
}

.exchange {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.exchange p {
  color: var(--primary-color);
  margin-left: 10px;
  font-size: 24px;
  font-weight: 450;
}
.timer {
  transform: scale(0.8);
  cursor: pointer;
}
.swap_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  width: 50%;
}
.swap_now {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.avatar_title p {
  margin-top: 5px;
  margin-left: 2px;
  font-size: 17px !important;
  font-weight: 700;
  margin-bottom: 10px;
}
.arrowDown_icon {
  margin-top: 8px !important;
  margin-bottom: 10px;
}
.fantoken {
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 90%;
  border-radius: 10px;
  padding: 3px 3px;
  margin: 10px 0px;
}
.fantoken p {
  font-weight: 450;
  font-size: 16px;
  align-items: flex-end;
  margin-left: 10px;
  margin-top: 12px;
  color: var(--text-color-light);
}

.swap img {
  margin-top: 30px;
  /* margin-bottom: 10px; */
  width: 45px;
  height: 45px;
}
.pgemconis {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  width: 50%;
}
.pgem {
  height: 38px;
  width: 38.6px;
}
.button {
  margin-top: 20px !important;
  width: 425px !important;
  height: 64px !important;
  background-color: #c6ff00 !important;
  color: black !important;
  font-size: 16px !important;
  border-radius: 10px !important;
}
.vline {
  border-bottom: 1px solid #dcf587;
  width: 100%;
  height: 12px;
  margin-top: -20px;
}
.pairtype {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 600;
  margin: 20px;
}
.expand_more {
  background-color: #13171d !important;
  margin-top: 20px !important;
  border-radius: 10px !important;
  width: 100% !important;
  padding: 15px 10px;
  min-height: 63px;
  display: flex;
  align-items: center;
}
.drax_pgem {
  width: 95% !important;
  display: flex;
  justify-content: space-between;
  /* align-items: center !important; */
}
.drax_percentage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* .drax_percentage img {
  width: 83px !important;
  height: 47px !important;
} */
.drax_percentage h6 {
  color: white;
  margin-left: 10px;
  font-size: 18px;
  width: 87px;
  height: 24px;
}
.last_percentage {
  margin-top: 10px;
  border: 1px solid #eb5757;
  height: 30px;
  width: 60px;
  border-radius: 8px;
  margin-left: -70px;
}
.last_percentage p {
  color: #eb5757;
  text-align: center;
  margin-top: 3px;
  /* margin-left: -70px; */
}
.twohundred {
  color: white !important;
  font-size: 17px;
  margin-right: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Buy_Sell {
  display: flex;
  justify-content: space-between;
}
.ByStreamer_main {
  display: flex !important;
}

.friendsIcon {
  display: flex;
  flex-direction: row;
}

.friendsIcon img {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  margin-top: 3px;
}
.friendsIcon p {
  color: #8e8ea9;
  margin-left: 10px;
  margin-top: 3px;
}
.Streamer_name {
  display: flex;
  align-items: center;
}
.Streamer_name p {
  margin-left: 5px;
  color: #ffffff;
  font-weight: 300;
}
.mekaverse {
  margin-top: -10px !important;
  color: #8e8ea9;
  font-size: 25px;
}

.streamer_viewr h3 {
  color: #ffffff !important;
  font-size: 20px;
  font-weight: 900;
}
.streamer_viewr p {
  color: #ffffff;
  font-weight: 300;
  font-size: 12px;
}

.liquidity h3 {
  color: #ffffff !important;
  font-size: 20px;
  font-weight: 900;
}
.liquidity p {
  color: #ffffff;
  font-weight: 300;
  font-size: 12px;
}
.Buy_now button {
  background: var(--primary-color);
  width: 105px;
  height: 43px;
  border-radius: 10px;
  text-align: center;
  color: white !important;
  /* margin-right: 20px; */
}

.Buy_now button:hover {
  background-color: var(--text-hover-color);
  color: var(--text-color) !important;
  box-shadow: var(--button-box-shadow);
}
.Sell_now button {
  background: #c6ff00;
  width: 165px;
  height: 43px;
  border-radius: 10px;
  text-align: center;
  color: #212134 !important;
  margin-top: 15px;
}
.swap_now button {
  width: 90%;
  height: 50px;
  font-size: medium;
  font-weight: bold;
  border-radius: 10px;
  text-align: center;
  color: #000000 !important;
  margin: 15px;
  /* text-emphasis: bold; */
}
.swap_now .buttonBuy {
  width: 50%;
}
.buttonBuy button {
  background-color: #a6f38d;
}
.swap_now .buttonSell {
  width: 50%;
}
.buttonSell button {
  background-color: #b1694e;
}

.css-i4bv87-MuiSvgIcon-root {
  color: #c6ff00;
  /* border: 1px solid #c6ff00 !important; */
  border-radius: 50% !important;
}

@media screen and (max-width: 650px) {
  .Buy_now button {
    width: 50px;
    font-size: 12px;
  }
}

@media screen and (max-width: 550px) {
  .money_exchange {
    width: 100%;
    margin-bottom: 20px;
  }
  .drax_percentage > h6 {
    font-size: 15px;
  }
  .drax_percentage > img {
    width: 50px !important;
    height: 27px !important;
  }
  .twohundred > h5 {
    font-size: 13px;
  }
  .last_percentage {
    padding: 2px;
  }
  .last_percentage > p {
    font-size: 13px;
  }
  .twohundred {
    margin-right: 0px;
  }
}
