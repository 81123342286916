.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: max-content;
}
.card_content{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  color: white;
}