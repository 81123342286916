.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  padding: 20px;
}

.container h1 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0.30000001192092896px;
  text-align: center;
  color: var(--primary-color);
}

.container .search {
  background: #13171d;
  border-radius: 8px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  color: white;
}

.container .search input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  color: white;
  padding: 10px 20px;
}

.container .search input:active,
.container .search input:focus {
  outline: none;
}

.container .search span {
  min-width: 45px;
  min-height: 45px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  background-position: center;
  background-repeat: no-repeat;
}

.container .filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex: unset;
}

.container .filters div {
  padding: 12px 10px;
  border-radius: 40px;
  background: #222831;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.30000001192092896px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.container .filters div span {
  min-width: 45px;
  min-height: 45px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(235, 87, 87, 0.24);
  background-position: center;
  background-repeat: no-repeat;
}

.container .content {
  flex: 1;
}

@media only screen and (max-width: 800px) {
  .container {
    padding: 0px;
  }
  .container h1 {
    font-size: 15px;
    line-height: 0px;
  }
}
