.announcements-loading-skeletons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}

.announcements-container {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  padding: 2% 3%;
  gap: 30px;
}

.announcement-item {
  width: 100%;
  border-bottom: 2px solid var(--primary-color);
  color: var(--text-color);
  padding-bottom: 20px;
}

.announcement-item > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.announcement-item > div > div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.announcement-item > div > div > p {
  min-width: 200px;
  margin-top: 0;
}

.announcement-item p {
  margin-top: 10px;
  width: 60%;
  text-align: justify;
  font-size: 14px;
  word-break: normal;
}

@media only screen and (max-width: 700px) {
  .announcement-item > div {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .announcement-item p {
    width: 90%;
  }
}
