* {
  box-sizing: border-box;
}

.welcome_back_create_account_button {
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-weight: bold;
}

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 35px;
  overflow: hidden;
}

.container section:nth-child(1) {
  flex: 1;
  max-width: 400px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
}

.container section:nth-child(1) form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
}

.container section:nth-child(2) {
  flex: 1;
  display: flex;
  justify-content: center;
}

.container section:nth-child(2) img {
  max-width: 400px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .container section:nth-child(1) {
    flex: 1;
    max-width: 100%;
    min-width: 100%;
    align-items: center;
    overflow: hidden;
  }

  .container section:nth-child(1) form {
    flex: 1;
    max-width: 400px;
    width: 100%;
  }
}
