.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: content-box;
}

.path {
  border-bottom: 0.5px solid var(--primary-color);
  max-height: 70px;
  min-height: 70px;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.path_child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.breadCrumb {
  display: flex;
  align-items: center;
  gap: 13px;
  cursor: pointer;
}

.breadCrumb h3 {
  font-weight: 450;
  color: var(--primary-color);
}

.data {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
}

@media only screen and (max-width: 800px) {
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
  }
  .breadCrumb {
    height: 25px;
  }
}
