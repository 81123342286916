* {
  box-sizing: border-box;
}

.container {
  display: flex;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--bg-color);
}

.dive {
  display: flex;
  width: 100%;
}
.container section {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: var(--bg-color);
}

.container .content {
  flex-grow: 1;
  width: calc(90% - 470px);
  margin-right: 200px;
  padding: 20px;
  box-sizing: border-box;
}

.footer_container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  color: var(--grey-color-light);
  font-size: 14px;
}

.footer_inner_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}

@media only screen and (max-width: 800px) {
  .container .content {
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    margin-right: 0px;
    padding-bottom: 150px;
  }
}
