.dbl {
  display: flex;
  flex-wrap: wrap;
}
.settingmain {
  display: flex;
  flex-wrap: wrap;
}
.maindiv2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px 0px 0px 20px;
}
.cardAvatar {
  width: 30%;
  height: auto;
}
.activitiesavatardetailsp {
  font-size: 16px;
  font-weight: 600;
}
.activitiesavatardetailsp2 {
  font-size: 14px;
  /* margin-top: -20px; */
}
.activitiesavatardetailsp3 {
  font-size: 12px;
  /* margin-top: -10px; */
}
button.activitiesavatardetailsbutton {
  text-transform: capitalize;
  border-radius: 156px;
  background-color: #222222;
  color: var(--text-color-light);
  border: 1px solid var(--primary-color);
  font-size: 13px;
  padding: 5px 25px;
  margin-top: 2px;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
}
button.activitiesavatardetailsbutton:hover {
  background-color: #1d1616;
}

.activitiesdetails {
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  /* margin-left: 100px !important; */
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  margin-top: -15px !important;
  color: var(--text-color-light);
}

.activitiesStack {
  width: 45%;
  height: auto;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--input);
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--primary-color);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column !important;
  justify-content: space-between !important;
}
.activitiesdetailsdiv {
  font-size: 14px;
  letter-spacing: 0.47px;
}
.activitiesdetailsp {
  margin-top: -5px;
}
.activitiesdetailsp2 {
  font-size: 32px;
  font-weight: 600;
  color: var(--text-color-light);
  /* margin-top: -15px; */
}
.streamswatchedmain {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  height: auto;
  background-color: var(--primary-color);
  color: var(--text-color-light);
  border-radius: 10px;
  padding: 5px 20px;
  position: relative;
  text-align: center;
}
.streamswatchedmainp {
  font-size: 12px;
  letter-spacing: 0.3%;
  margin-top: 0px;
}
.streamswatchedmainp2 {
  font-size: 28px;
  font-weight: 700;
  margin-top: 15px;
}
.arrowicon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.activitiesstreamdetails {
  width: 50%;
  height: auto;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--input);
  margin-top: 20px;
  color: var(--primary-color);
}
.streamdetails {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 5px;
}

.stream_time_select_container {
  display: flex;
}

.stream_time_select_main {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .activitiesdetails {
    width: 100%;
    padding: 0;
    margin: 0 !important;
  }
  .activitiesdetailsdiv {
    font-size: 16px;
  }
  .activities-detailsp {
    margin-top: -18px;
  }
  .activities-detailsp2 {
    font-size: 26px;
  }
  .streams-watched-main {
    width: 88%;
    margin-top: 20px;
  }
  .activitesstack {
    flex-wrap: wrap;
  }
  .activitiesstreamdetails {
    width: 100%;
    margin-top: 10px;
  }
  .activitiesStack {
    width: 100%;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .streamswatchedmain {
    width: 100%;
    margin-bottom: 10px;
  }
  .maindiv2 {
    padding: 0;
  }

  .activityStreamerDatInputContainer {
    width: 34vw;
    padding-right: 5px !important;
  }
  .activityTimelineHeader {
    font-size: 12px;
  }

  .stream_time_select_container {
    flex-direction: column;
    gap: 10px;
  }

  .stream_time_select_main {
    gap: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .activityStreamerDatInputContainer {
    width: 45vw;
  }

  .stream_time_select_main {
    gap: 10px;
  }
}
