.video-streaming {
  margin-top: -70px;
  margin: auto 24px;
}
.video-title {
  font-size: 20px;
  font-weight: 500;
  width: 70%;
}

.streamer-box {
  width: 30% !important;
  overflow: auto;
  padding: 0px 10px 0px 5px;
}

.streamer-box::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.streamer-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.streamer-box::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
.streamer-box::-webkit-scrollbar-thumb:hover {
  background: var(--text-accent-color);
}

.stream-comment-class {
  font-size: 10px;
  color: var(--text-color);
  line-break: anywhere;
  line-height: 14px;
  font-weight: "normal";
}

.stream-desktop-container {
  height: 57vh !important;
}

.Streaaming-views {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 45px;
  margin-top: -15px;
}
.Streaaming-views > p:first-child {
  font-size: 18px;
  letter-spacing: 0.92px;
  color: #808191;
}
.dot-icon {
  font-size: 9px !important;
}
.stream-status-btn button {
  width: 120px;
  height: 40px;
  padding: 5px, 4px, 5px, 4px;
  background-color: var(--neutral900);
  margin-right: 10px;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--primary-color);
  font-weight: 400;
  letter-spacing: 0.55px;
  cursor: inherit;
  border-radius: 7px;
}
.stream-status-btn button:hover {
  background-color: var(--neutral900);
}
.stream-status-btn button:nth-child(2) {
  width: 90px;
  background-color: var(--alternate-color);
}
.streamer-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 55px;
}
.streamer-avatar {
  width: 50px !important;
  height: 50px !important;
  border-radius: 12px !important;
}
.streamer-avatar-detail p:first-child {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.48px;
  margin-top: 0;
}
.streamer-avatar-detail p:nth-child(2) {
  font-size: 14px;
  letter-spacing: 0.48;
  color: var(--neutral500);
  margin-top: -25px;
}
.follow-btn button {
  width: 126px;
  height: 36px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.32px;
  text-transform: capitalize;
  background-color: #b7e514;
  color: #000000;
  border-radius: 25px;
  padding: 3px, 16px, 3px, 16px;
}
.follow-btn button:hover {
  background-color: #fff;
}
.stream-status-btn .golivebtn {
  width: 90%;
  margin: 24px 5%;
  cursor: pointer;
}
@media only screen and (max-width: 1199px) {
  .video-streaming {
    margin-top: -110px;
  }

  .stream-desktop-container {
    height: 50vh !important;
  }

  .streamer-box {
    width: 40% !important;
  }
}
@media only screen and (max-width: 899px) {
  .video-streaming {
    margin-left: -20px;
    margin-right: -20px;
  }

  .video-title {
    font-size: 16px;
    width: 90%;
  }
  .Streaaming-views {
    margin-right: 0px;
    margin-bottom: 5px;
  }
  .Streaaming-views > p:first-child {
    font-size: 12px;
  }
  .stream-status-btn button {
    width: 75px;
    height: 30px;
    padding: 4px;
    font-size: 10px;
    margin-right: 0;
  }
  .stream-status-btn button:nth-child(2) {
    width: 30px;
    margin-left: 5px;
  }
  .streamer-profile {
    margin-right: 0px;
  }
  .streamer-avatar-detail p:first-child {
    font-size: 18px;
    margin-top: 0;
  }
  .streamer-avatar-detail p:nth-child(2) {
    font-size: 12px;
    margin-top: -20px;
  }
  .follow-btn button {
    width: 100px;
    height: 32px;
    font-size: 12px;
  }
  .streamer-box {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 650px) {
  .streamer-box {
    width: 100% !important;
  }
}

@media only screen and (max-width: 500px) {
  .stream-desktop-container {
    height: 30vh !important;
  }
}

/*

Video Player controls

.vjs-remaining-time - For hiding remaining time
.vjs-progress-control - For hiding video progress slider

*/

.vjs-remaining-time {
  display: none;
}

.vjs-progress-control {
  display: none !important;
}
