.watchToEarn-container {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: 0 0 5px cyan, 0 0 25px cyan;
  animation: animate 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border: 2px solid var(--primary-color);
  --watchToEarn-color: var(--primary-color);
}

.watchToEarn-container img {
  height: 48px;
  width: 48px;
}

.watchToEarn-container:hover {
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .watchToEarn-container {
    width: 50px;
    height: 50px;
  }
  .watchToEarn-container img {
    width: 40px;
    height: 40px;
  }
}

@keyframes animate {
  0% {
    box-shadow: 0 0 5px var(--watchToEarn-color),
      0 0 25px var(--watchToEarn-color);
  }
  25% {
    box-shadow: 0 0 5px cyan, 0 0 25px cyan, 0 0 25px cyan, 0 0 50px cyan;
  }
  50% {
    box-shadow: 0 0 5px var(--watchToEarn-color),
      0 0 25px var(--watchToEarn-color), 0 0 50px var(--watchToEarn-color),
      0 0 100px var(--watchToEarn-color);
  }
  75% {
    box-shadow: 0 0 5px cyan, 0 0 25px cyan, 0 0 50px cyan, 0 0 100px cyan;
  }
  100% {
    box-shadow: 0 0 5px var(--watchToEarn-color),
      0 0 25px var(--watchToEarn-color), 0 0 50px var(--watchToEarn-color),
      0 0 50px var(--watchToEarn-color);
    /* box-shadow: 0 0 5px var(--watchToEarn-color),
      0 0 25px var(--watchToEarn-color), 0 0 100px var(--watchToEarn-color),
      0 0 200px var(--watchToEarn-color); */
  }
}
