* {
  margin: 0;
  padding: 0;
}

.link-style {
  text-decoration: none;
  color: #fff;
}

.border-1-text-color {
  border: 1px solid var(--text-color);
}

select {
  color: var(--text-color-light);
}

input[type="select"] {
  color-scheme: dark !important;
}

input[type="date"] {
  color-scheme: dark !important;
}

input[type="datetime-local"] {
  color-scheme: dark !important;
}

input::placeholder {
  color: var(--input-placeholder) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

button:disabled {
  background-color: gray;
}

.form1 {
  width: 100%;
  display: flex;
  flex: 1;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.edit_profile_icon_container {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -30px;
}
.edit_profile_icon_container:hover {
  cursor: pointer;
}
.create_profile {
  display: flex;
  color: #c6ff00;
  margin-top: 20px;
  align-items: center;
}
.create_profile p {
  margin-left: 20px;
  font-size: 22px;
}
.vl {
  border-bottom: 1px solid #dcf587;
  width: 82%;
  height: 12px;
  margin-top: -15px;
}

.Avatarprofile {
  position: relative;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding-top: 60px;
}

.edit-icon {
  float: right;
  cursor: pointer;
}
.left_form {
  margin-top: 20px;
}

.form {
  width: 100%;
  padding: 10px;
}

.forminput {
  background-color: var(--input) !important;
  color: var(--text-color-light);
  border: none;
  padding: 15px 8px;
  width: 100% !important;
  border-radius: 8px;
  margin: 0px 10px;
}
.formlabel {
  margin-bottom: 5px;
  margin-left: 10px;
}
.right_form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.connect {
  display: flex;
  flex-wrap: wrap;
  color: #c6ff00 !important;
  font-size: 10px;
  max-width: 400px;
  width: 100%;
  margin-left: -15px;
  margin-top: 10px;
  justify-content: center;
}

.connectp {
  margin-left: 0px !important;
  margin-top: 10px;
}

.connectimg {
  margin-left: 10px;
  height: 30px !important;
  width: 29px !important;
  cursor: pointer;
}
button.creataccountbutton {
  background-color: var(--primary-color) !important;
  color: var(--text-color-light);
  text-align: center;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  padding: 10px;
  height: 50px;
  text-transform: capitalize;
  font-weight: 700;
  box-sizing: border-box;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.login {
  max-width: 400px;
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.loginh6 {
  font-size: 10px;
  color: #e5e5e5;
  margin: 0px !important;
}
.loginp {
  margin: 0 !important;
  color: #c6ff00;
  font-size: 12px;
  cursor: pointer;
  font-weight: 700;
}
.error {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
.updateButton {
  width: 60%;
  height: 50px;
  background-color: var(--primary-color);
}

.formmaindiv {
  width: 100%;
  display: flex;
  flex: 2;
  flex-direction: row;
  flex-wrap: wrap;
}

input {
  background-color: var(--input) !important;
  color: var(--text-color-light) !important;
  border: none;
  height: 40px;
  padding: 0px 8px;
  width: 100% !important;
  border-radius: 8px;
}

label {
  color: var(--text-color);
}

.form-error {
  font-size: 12px;
  color: red;
}

.dzu-dropzone {
  border: 1px solid var(--text-color) !important;
  overflow: hidden !important;
}
.dzu-previewContainer {
  border-bottom: 0px !important;
}
.dzu-inputLabel {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--text-color) !important;
}
.dzu-dropzone .dzu-submitButtonContainer {
  display: none !important;
}
.dzu-previewContainer {
  background-color: #ccc;
}

@media only screen and (max-width: 799px) {
  .forminput {
    background-color: #13171d !important;
    color: #ababab;
    border: none;
    height: 40px;
    padding: 0px 8px;
    max-width: 400px;
    width: 100% !important;
    border-radius: 8px;
    margin: 5px 10px;
  }
  .form1 {
    padding: 0px;
  }
  .formmaindiv {
    width: 90%;
    display: flex;
    flex: 4;
    flex-direction: column;
  }
  .form1 {
    flex: 3;
  }
  .Avatarprofile {
    justify-content: center;
    margin-bottom: 15px;
    height: 200px;
    flex: unset;
    padding-top: 0px;
  }
}
