* {
  box-sizing: border-box;
  margin: 0;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}

.comments_box_container {
  height: 25vh;
  overflow: auto;
  padding-bottom: 50px;
}

.comments_box_container::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.comments_box_container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.comments_box_container::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
.comments_box_container::-webkit-scrollbar-thumb:hover {
  background: var(--text-accent-color);
}

.videoContainer {
  position: relative;
}

.tencentPlayer {
  width: 100%;
  height: 100%;
}

.video_player {
  width: 100%;
  height: 100%;
}
.videoContainer video {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.metadata {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: unset !important;
}

.metadata h1 {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.metadata .metdataChild {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.metadata .metdataChild div {
  display: flex;
  align-items: center;
  gap: 17px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.9152542352676392px;
  color: #808191;
}

.metadata .metdataChild div:nth-child(2) button:nth-child(1) {
  background: #eb5757;
  border-radius: 7.32203px;
  padding: 10px 17px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5491525530815125px;
  color: #c6ff00;
  border: none;
}

.metadata .metdataChild div:nth-child(2) button:nth-child(2) {
  background: #c6ff00;
  border-radius: 7.32203px;
  padding: 10px 17px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5491525530815125px;
  color: black;
  border: none;
  cursor: pointer;
}

.commentAndLike {
  display: flex;
  gap: 25px;
  align-items: center;
}

.commentAndLike span {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #8e8ea9;
}
.react-awesome-player-container {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .metadata {
    align-items: center;
  }
  .metadata .metdataChild {
    flex-direction: column;
  }
  .video_player {
    height: 200px;
  }

  .container {
    gap: 5px;
  }
}
