.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding-bottom: 50px;
}

.gif_container {
  width: 50%;
  height: 350px;
  position: relative;
}

.gif_container > img {
  width: 100%;
  height: 100%;
}

.gaming_container {
  width: 50%;
  height: 500px;
  position: relative;
}

.gaming_container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar_button_container {
  position: absolute;
  bottom: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  background-color: var(--primary-color);
  color: var(--text-color-light);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 16px;
}

.button:hover {
  cursor: pointer;
  opacity: 0.9;
}

@media screen and (width <= 550px) {
  .gif_container {
    width: 100%;
  }

  .gaming_container {
    width: 100%;
  }
}
