:root {
  --appbar-bg-color: var(--bg-color);
  --appbar-active-color: var(--primary-color);
}

.list:hover {
  cursor: pointer;
}

.navigation-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  z-index: 10000;
  bottom: 0;
  border-top: 1px solid gray;
}

.navigation {
  width: 100vw;
  height: 70px;
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation ul {
  display: flex;
  width: 350px;
  padding: 0px;
}

.navigation ul li {
  position: relative;
  list-style: none;
  width: 70px;
  height: 70px;
  z-index: 1;
}

.navigation ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.5em;
  text-align: center;
  transition: 0.5s;
  color: var(--appbar-bg-color);
}

/* .navigation ul li.active a .icon {
  transform: translateY(-35px);
} */

.navigation ul li.active a span svg {
  color: var(--text-color-light);
}

.navigation ul li a .text {
  position: absolute;
  color: var(--text-color);
  font-weight: 400;
  font-size: 14px !important;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  margin: 0;
  transform: translateY(20px);
}

/* .navigation ul li.active a .text {
  opacity: 1;
  transform: translateY(10px);
} */

.indicator {
  position: absolute;
  width: 70px;
  height: 70px;
  background: var(--appbar-active-color);
  border-radius: 50%;
  border: 6px solid var(--appbar-bg-color);
}

.indicator::before {
  content: "";
  position: absolute;
  left: -22px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-right-radius: 20px;
  box-shadow: 0px -10px 0 0 transparent;
}

.indicator::after {
  content: "";
  position: absolute;
  right: -22px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-left-radius: 20px;
  box-shadow: 0px -10px 0 0 transparent;
}

.navigation ul li:nth-child(1).active ~ .indicator {
  transform: translateX(calc(70px * 0));
}

.navigation ul li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(70px * 1));
}

.navigation ul li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(70px * 2));
}

.navigation ul li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(70px * 3));
}

.navigation ul li:nth-child(5).active ~ .indicator {
  transform: translateX(calc(70px * 4));
}
